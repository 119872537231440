import authRoles from '../../../../auth/authRoles';
import {lazy} from "react";

const FooterContentMultiLanguageCmsFeatureFlag = lazy(() => import('./footerContent/FooterContentMultiLanguageCmsFeatureFlag'))
const FooterLanguageContent = lazy(() => import('./footerContent/FooterLanguageContent'))
const FooterContentOld = lazy(() => import('./footerContent/FooterContentOld'))
const Footer = lazy(() => import('./footer/Footer'))

const FooterAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/apps/frontend-settings/footer/:layoutElementUid/content',
			element: <FooterContentMultiLanguageCmsFeatureFlag />
		},
		{
			path: '/apps/frontend-settings/footer/:layoutElementUid/:languageUid/content',
			element: <FooterLanguageContent />
		},
		{
			path: '/apps/frontend-settings/footer/content',
			element: <FooterContentOld />
		},
		/* {
			path: '/apps/frontend-settings/footer/:languageUid/content',
			component: React.lazy(() => import('./footerContent/FooterLanguageContent'))
		}, */
		{
			path: '/apps/frontend-settings/footer',
			element: <Footer />
		}
	]
};

export default FooterAppConfig;
