import React, { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const EventGroup = lazy(() => import('./wizard/EventGroup'));
const EventGroups = lazy(() => import('./list/EventGroups'));

const EventGroupsAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/apps/event-group',
			element: <EventGroup />
		},
		{
			path: '/apps/event-group/:documentUid',
			element: <EventGroup />
		},
		{
			path: '/apps/event-group/:documentUid/:tab',
			element: <EventGroup />
		},
		{
			path: '/apps/event-groups',
			element: <EventGroups />
		}
	]
};

export default EventGroupsAppConfig;
