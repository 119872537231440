import { createSlice } from '@reduxjs/toolkit';
import { updateNavigationItem as updateNavigationItemFuse } from '../../../../store/fuse/navigationSlice';
import { removeNavChildren } from '../../../../../AttUtils';
import firebaseService from '@ameroservices-platform/shared/services/firebase';

const initialState = {
	navigations: [],
	navigation: [],
	allowedPages: [],
	loading: false
};

const navigationsSlice = createSlice({
	name: 'navigationsApp/navigation',
	initialState,
	reducers: {
		setNavigations(state, action) {
			state.navigations = action.payload;
		},
		setNavigation(state, action) {
			state.navigation = action.payload;
		},
		setAllowedPages(state, action) {
			state.allowedPages = action.payload;
		},
		setLoading(state, action) {
			state.loading = action.payload;
		},
		updateNavigationItemStore(state, action) {
			const copy = [...state.navigation];
			const index = copy.findIndex(f => f.id === action.payload.id);
			copy[index] = action.payload.item;
			state.navigation = copy;
		}
	}
});

export const {
	setNavigations,
	setNavigation,
	updateNavigationItemStore,
	setAllowedPages,
	setLoading
} = navigationsSlice.actions;

export default navigationsSlice.reducer;

export const navigationsListener = () => (dispatch, getState) => {
	const db = firebaseService.getContentRootDB();
	return db
		.collection('navigations')
		.where('deleted', '==', false)
		.onSnapshot(q => {
			let navs = [];
			q.forEach(doc => {
				navs.push({ ...doc.data(), id: doc.id });
			});
			dispatch(setNavigations(navs));
			navs = navs.map(n => ({
				id: `navigation-custom-${n.id}`,
				title: n.id,
				type: 'item',
				icon: 'navigation',
				url: `/apps/frontend-settings/navigations/${n.id}`,
				exact: true
			}));
			navs.push({
				id: 'navigation-create',
				title: 'Opret ny',
				type: 'item',
				icon: 'add',
				url: '/apps/frontend-settings/navigations',
				exact: true
			});
			removeNavChildren(dispatch, getState, 'navigations');
			dispatch(
				updateNavigationItemFuse('navigations', {
					id: 'navigations',
					title: 'Navigationer',
					type: 'collapse',
					icon: 'wallpaper',
					children: navs
				})
			);
		});
};

export const navigationListener = id => dispatch => {
	const db = firebaseService.getContentRootDB();
	return db
		.collection('navigations')
		.doc(id)
		.onSnapshot(q => {
			const data = q.data();
			dispatch(setNavigation(Object.values(data.navigation).sort((a, b) => a.order - b.order)));
		});
};

export const allowedPagesListener = () => dispatch => {
	const db = firebaseService.getContentRootDB();
	return db.collection('pages').onSnapshot(q => {
		const pages = [];
		q.forEach(doc => {
			pages.push({ ...doc.data(), id: doc.id });
		});
		pages.push({ uri: '/' });
		dispatch(setAllowedPages(pages.map(m => m.uri)));
	});
};

export const updateNavigation = (id, navigation) => async dispatch => {
	dispatch(setNavigation(navigation));
	const nav = {};
	let order = 0;
	navigation.forEach(n => {
		nav[n.id] = { ...n, order };
		order += 1;
	});
	await firebaseService.callFunctionByName('contentNavigationsUpdateNavigation', {
		navigationId: id,
		navigation: nav
	});
};

export const updateNavigationItem = (navigationId, id, item) => async dispatch => {
	dispatch(updateNavigationItemStore({ id, item }));
	await firebaseService.callFunctionByName('contentNavigationsUpdateNavigationItem', {
		navigationId,
		id,
		item
	});
};

export const createNavigation = async id => {
	await firebaseService.callFunctionByName('contentNavigationsCreateNavigation', {
		navigationId: id
	});
};

export const deleteNavigation = async id => {
	await firebaseService.callFunctionByName('contentNavigationsDeleteNavigation', {
		navigationId: id
	});
};

export const selectNavigations = state => state.navigationsApp.navigations;
export const selectNavigation = state => state.navigationsApp.navigation;
export const selectAllowedPages = state => state.navigationsApp.allowedPages;
export const selectLoading = state => state.navigationsApp.loading;
