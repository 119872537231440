import firebaseService from '@ameroservices-platform/shared/services/firebase';
import subscriptionState from '@ameroservices-platform/shared/enums/subscriptionState';

export const resyncTypes = Object.freeze({
	PRODUCTS: 'products',
	CUSTOMERS: 'customers',
	PRODUCT_GROUPS: 'productGroups',
	CUSTOMER_TICKETS: 'customerTickets',
	CUSTOMER_SUBSCRIPTIONS: 'customerSubscriptions',
	SUBSCRIPTION_TYPES: 'subscriptionTypes',
	DISCOUNT_CODE_TYPES: 'discountCodeTypes',
	ORDERS: 'orders',
	BIG_QUERY: 'bigQuery',
	INVOICES: 'invoices',
	ELASTIC_SEARCH: 'elasticSearch',
	BOKUN: 'bokun',
	LOCAL_ELASTIC_SEARCH: 'localElasticSearch',
	GLOBAL_DATA: 'global data'
});

export const resyncDestinations = Object.freeze({
	FLEXPOS: 'flexpos',
	ELASTIC_SEARCH: 'elasticSearch',
	FIRESTORE: 'firestore',
	GLOBAL: 'global'
});

export const sendEmailToCustomers = async (type, customers, confirm) => {
	const resp = await firebaseService.callFunctionByName('sysAdminSendEmailToCustomers', {
		type,
		customers,
		confirm
	});
	return resp.data;
};

export const sendSMSToCustomers = async (type, text, confirm) => {
	const resp = await firebaseService.callFunctionByName('sysAdminSendSMSToCustomers', {
		type,
		text,
		confirm
	});
	return resp.data;
};

export const extendAllActiveSubscriptionsExpireDate = async (confirm, amount, subscriptionTypeUid, period) => {
	const resp = await firebaseService.callFunctionByName('sysAdminExtendAllActiveSubscriptionsExpireDate', {
		amount,
		subscriptionTypeUid,
		period,
		confirm
	});
	return resp.data;
};

export const runJobSubscriptionCheck = async allowedSubscriptionUids => {
	const resp = await firebaseService.callFunctionByName('sysAdminRunJobSubscriptionCheck', {
		allowedSubscriptionUids
	});
	return resp.data;
};

export const renewSubscriptions = async (subscriptions, mailTemplate) => {
	const resp = await firebaseService.callFunctionByName('sysAdminRenewSubscriptions', {
		subscriptions,
		forceMailTemplate:
			mailTemplate && mailTemplate.id && mailTemplate.versions && mailTemplate.versions.find(ver => ver.active)
				? { templateId: mailTemplate.id, versionId: mailTemplate.versions.find(ver => ver.active).id }
				: null
	});
	return resp.data;
};

export const runAutomaticPayment = async (customerUid, subscriptionUid) => {
	const resp = await firebaseService.callFunctionByName('sysAdminRunAutomaticPayment', {
		customerUid,
		subscriptionUid
	});
	return resp.data;
};

export const getMailTemplates = async () => {
	const response = await firebaseService.callFunctionByName('mailTemplateGetAllTemplates', {});
	return Object.values(response.data.templates);
};

export const createCustomJob = async (type, extraData) => {
	const resp = await firebaseService.callFunctionByName('sysAdminCreateCustomJob', {
		type,
		extraData
	});
	return resp.data;
};

export const getSubscriptionsToRenew = async (daysBefore = null, sendRenewalTime = null) => {
	const resp = await firebaseService.callFunctionByName('sysAdminGetSubscriptionsToRenew', {
		daysBefore,
		sendRenewalTime
	});
	return resp.data;
};

export const getMigrationFunctions = async cb => {
	const resp = await firebaseService.callFunctionByName('sysAdminMigrationGetFunctions');
	cb(resp.data);
};

export const runMigrationFunctions = async organisationUids => {
	await firebaseService.callFunctionByName('sysAdminMigrationRunFunctions', { organisationUids });
};

export const runAJob = async (job, organisations) => {
	const data = await firebaseService.callFunctionByName('sysAdminMigrationRunJob', { job, organisations });
	return data && data.data ? data.data : null;
};

export const runFlexposProductsJob = async (organisationId, flexposClientId, commitChanges) => {
	const data = await firebaseService.callFunctionByName('sysAdminMigrationRunFlexposProductsJob', {
		organisationId,
		flexposClientId,
		commitChanges
	});
	return data && data.data ? data.data : null;
};

export const deleteOldFlexPOSCustomers = async confirm => {
	const data = await firebaseService.callFunctionByName('sysAdminDeleteOldFlexPOSCustomers', { confirm });
	return data.data;
};

export const resyncItemsToSyncEngine = async (type, destination) => {
	const data = await firebaseService.callFunctionByName('sysAdminResyncItemsToSyncEngine', {
		type,
		destination
	});
	return data && data.data ? data.data : null;
};

export const resyncItemToSyncEngine = async (type, destination, itemUid) => {
	const data = await firebaseService.callFunctionByName('sysAdminResyncItemToSyncEngine', {
		type,
		destination,
		itemUid
	});
	return data && data.data ? data.data : null;
};

export const runDelete = async (type, selected, deleteAll) => {
	await firebaseService.callFunctionByName('sysAdminRunDelete', {
		organisationUid: firebaseService.getOrganisationId(),
		type,
		selected,
		deleteAll
	});
};

export const createImportJob = async (name, type, fileLocation, onlyAmeroAdmin, extraData) => {
	const resp = await firebaseService.callFunctionByName('organisationImportJobCreateImportJob', {
		name,
		type,
		onlyAmeroAdmin,
		...extraData,
		fileLocation
	});
	return resp.data.id;
};

export const createDataValidationJob = async (type, fileLocations, start, end) => {
	const resp = await firebaseService.callFunctionByName('sysAdminCreateDataValidation', {
		type,
		fileLocations,
		start,
		end
	});
	return resp.data.id;
};
export const createExportJob = async (name, type, onlyAmeroAdmin, extraData) => {
	const resp = await firebaseService.callFunctionByName('organisationExportJobCreateExportJob', {
		name,
		type,
		onlyAmeroAdmin,
		extraData
	});
	return resp.data.id;
};

export const updateTicketValidationUse = async (organisationUid, type) => {
	await firebaseService.callFunctionByName('sysAdminUpdateTicketValidationUse', { organisationUid, type });
};

export const dataValidationsListener = callback => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('dataValidations')
		.where('deleted', '==', false)
		.onSnapshot(query => {
			callback(query.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		});
};

export const subscriptionsListener = callback => {
	const db = firebaseService.getRootDB();
	return db
		.collectionGroup('customer_subscriptions')
		.where('deleted', '==', false)
		.where('state', 'in', [subscriptionState.RENEWAL, subscriptionState.ACTIVE])
		.where('organisationUid', '==', firebaseService.getOrganisationId())
		.onSnapshot(query => {
			callback(query.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		});
};

export const subscriptionTypesListener = callback => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('subscriptionTypes')
		.where('deleted', '==', false)
		.onSnapshot(query => {
			callback(query.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		});
};

export const exportJobsListener = (type, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('exportJobs')
		.where('deleted', '==', false)
		.where('type', '==', type)
		.onSnapshot(query => {
			const exportJobs = {};
			query.forEach(doc => {
				exportJobs[doc.id] = { ...doc.data(), id: doc.id };
			});
			callback(exportJobs);
		});
};

export const runActionsForOrders = async (orders, actions) => {
	const resp = await firebaseService.callFunctionByName('sysAdminRunActionsForOrders', {
		orders,
		actions
	});
	return resp.data;
};

export const getOrders = async orders => {
	const resp = await firebaseService.callFunctionByName('sysAdminGetOrders', {
		orders
	});
	return resp.data;
};

export const importJobsListener = callback => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('importJobs')
		.where('deleted', '==', false)
		.where('type', '==', 'reportValidation')
		.onSnapshot(query => {
			const importJobs = [];
			query.forEach(doc => {
				importJobs.push({ ...doc.data(), id: doc.id });
			});
			importJobs.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
			callback(importJobs);
		});
};

export const importJobListener = (importJobUid, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('importJobs')
		.doc(importJobUid)
		.onSnapshot(doc => {
			callback({ ...doc.data(), id: doc.id });
		});
};

export const jobListener = (jobUid, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('jobs')
		.doc(jobUid)
		.onSnapshot(doc => {
			callback({ ...doc.data(), id: doc.id });
		});
};

export const collectionsListener = (collection, callback) => {
	const db = firebaseService.getRootDB();
	return db
		.collectionGroup(collection)
		.where('organisationUid', '==', firebaseService.getOrganisationId())
		.where('deleted', '==', false)
		.onSnapshot(querySnapshot => {
			const tickets = [];
			querySnapshot.forEach(doc => {
				const docData = doc.data();
				tickets.push({ ...docData, id: doc.id });
			});
			callback(tickets);
		});
};

export const ticketsListener = callback => {
	const db = firebaseService.getRootDB();
	return db
		.collectionGroup('customer_tickets')
		.where('organisationUid', '==', firebaseService.getOrganisationId())
		.where('deleted', '==', false)
		.onSnapshot(querySnapshot => {
			const tickets = [];
			querySnapshot.forEach(doc => {
				const docData = doc.data();
				tickets.push({ ...docData, id: doc.id });
			});
			callback(tickets);
		});
};

export const organisationsListener = callback => {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.where('deleted', '==', false)
		.onSnapshot(querySnapshot => {
			const organisations = [];
			querySnapshot.forEach(doc => {
				const docData = doc.data();
				organisations.push({ ...docData, id: doc.id });
			});
			callback(organisations);
		});
};

export const organisationListener = (uid, callback) => {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.doc(uid)
		.onSnapshot(q => {
			callback({ ...q.data(), id: q.id });
		});
};

export const callFunction = async data => {
	const resp = await firebaseService.callFunctionByName('flexposPurgeFlexposData', data);
	return resp.data;
};

export const callMiscFunction = async data => {
	const resp = await firebaseService.callFunctionByName('globalDataResyncGlobalData', data);
	return resp.data;
};
