import FuseUtils from '@ameroservices-platform/shared/fuse/utils';
import appsConfigs from '@ameroservices-platform/attraction-backend/app/main/apps/appsConfigs';
import LoginConfig from '@ameroservices-platform/attraction-backend/app/main/login/LoginConfig';
import LogoutConfig from '@ameroservices-platform/attraction-backend/app/main/logout/LogoutConfig';
import FuseLoading from '@ameroservices-platform/attraction-backend/fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import authRoles from '@ameroservices-platform/attraction-backend/app/auth/authRoles';
import AuthRedirectConfig from '@ameroservices-platform/attraction-backend/app/main/authRedirect/AuthRedirectConfig';
import RedirectLink from '@ameroservices-platform/attraction-backend/app/ui-components/RedirectLink';

const routeConfigs = [...appsConfigs];

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, authRoles.user),
	...FuseUtils.generateRoutesFromConfigs([AuthRedirectConfig, LogoutConfig, LoginConfig]),
	{
		path: '/status',
		element: <RedirectLink to="https://status.console.ameroservices.dk/" target="_blank" />
	},
	{
		path: '/docs',
		element: <RedirectLink to="https://attraction.docs.ameroservices.dk/" target="_blank" />
	},
	{
		path: '/',
		element: <Navigate to="apps/tickets" />,
	},
	{
		path: 'loading',
		element: <FuseLoading />,
	},
	{
		path: '*',
		element: <Navigate to="apps/error/404" />,
	},
];

export default routes;
