import { combineReducers } from '@reduxjs/toolkit';
import user from './userSlice';
import users from './usersSlice';
import country from './countrySlice';

const sharedReducers = combineReducers({
	users,
	country,
	user,
});

export default sharedReducers;
