import BrowserRouter from '../fuse/core/BrowserRouter';
import FuseAuthorization from '../fuse/core/FuseAuthorization';
import FuseLayout from '../fuse/core/FuseLayout';
import FuseTheme from '../fuse/core/FuseTheme';
import { SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { selectCurrLangDir } from './store/i18nSlice';
import withAppProviders from './withAppProviders';
import { Auth } from './auth';
import { LocalizationProvider } from '@mui/lab';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import StoreInit from './store/StoreInit';
import moment from 'moment-timezone';
import 'moment/locale/da';

// import axios from 'axios';
/**
 * Axios HTTP Request defaults
 */
// axios.defaults.baseURL = "";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

const emotionCacheOptions = {
	rtl: {
		key: 'muirtl',
		stylisPlugins: [],
		insertionPoint: document.getElementById('emotion-insertion-point')
	},
	ltr: {
		key: 'muiltr',
		stylisPlugins: [],
		insertionPoint: document.getElementById('emotion-insertion-point')
	}
};

moment.tz.setDefault('Europe/Copenhagen');
moment.locale('da');

const App = () => {
	const langDirection = useSelector(selectCurrLangDir);

	return (
		<CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
			<Auth>
				<StoreInit>
					<BrowserRouter>
						<FuseAuthorization>
							<FuseTheme>
								<SnackbarProvider
									maxSnack={5}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right'
									}}
									classes={{
										containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99'
									}}
								>
									<FuseLayout />
								</SnackbarProvider>
							</FuseTheme>
						</FuseAuthorization>
					</BrowserRouter>
				</StoreInit>
			</Auth>
		</CacheProvider>
	);
};

export default withAppProviders(App)();
