const eventGroupState = {
	DRAFT: 'draft',
	ACTIVE: 'active',
	INACTIVE: 'inactive',
	HELD: 'held',
	UNKNOWN: 'unknown'
};

export const eventGroupStateTranslate = {
	draft: 'Kladde',
	active: 'Aktiv',
	inactive: 'Inaktiv',
	[eventGroupState.HELD]: 'Afholdt',
	unknown: 'Ukendt'
};

export default eventGroupState;
