const eventGroupType = {
	SUBSCRIPTION_EVENTS: 'subscriptionEvents',
	ENTRANCE_EVENTS: 'entranceEvents',
	EVENT_EVENTS: 'eventEvents'
};

export const eventGroupTypeTranslate = {
	[eventGroupType.SUBSCRIPTION_EVENTS]: 'Medlemskabsperioder',
	[eventGroupType.ENTRANCE_EVENTS]: 'Entréperioder',
	[eventGroupType.EVENT_EVENTS]: 'Begivenheder'
};

export default eventGroupType;
