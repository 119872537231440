import {lazy} from 'react';
import authRoles from '../../../../auth/authRoles';

const TaxRatesTaxRates = lazy(() => import('./taxRates/TaxRates'));

const TaxRatesAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/apps/settings/vat',
			element: <TaxRatesTaxRates />,
		}
	]
};

export default TaxRatesAppConfig;
