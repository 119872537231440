import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Snackbar, TextField, Alert, Autocomplete } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import * as ReactDOM from 'react-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectMainTheme } from '../../store/fuse/settingsSlice';
import ConfirmationDialog from '../../ui-components/ConfirmationDialog';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import { getFlexposLinkWithSyncActiveExists, syncTypes } from '../../main/apps/organisations/OrganisationsFirestore';
import { resyncDestinations, resyncItemsToSyncEngine, resyncTypes } from '../../main/apps/sysAdmin/SysAdminFirestore';

export const defaultResyncTypes = {
	[resyncTypes.CUSTOMERS]: 'Kunder',
	[resyncTypes.PRODUCTS]: 'Produkter',
	[resyncTypes.SUBSCRIPTION_TYPES]: 'Medlemskabstyper',
	// [resyncTypes.CUSTOMER_TICKETS]: 'Billetter',
	[resyncTypes.BIG_QUERY]: 'Big query',
	[resyncTypes.INVOICES]: 'Månedsberegninger',
	[resyncTypes.ELASTIC_SEARCH]: 'Elastic search' /* ,
	[resyncTypes.LOCAL_ELASTIC_SEARCH]: 'Elastic Search Lokalt' */
};

export const defaultResyncDestinations = {
	[resyncDestinations.FLEXPOS]: 'FlexPOS' /* ,
	[resyncDestinations.ELASTIC_SEARCH]: 'Elastic search' */
};

function SyncAdminModal({ open, onClose }) {
	const {
		bokunIntegration,
		changeOfCustomerSyncToSubscriptionCardSync,
		movedProductGroupsToElasticSearch,
		discountCodes
	} = useFlags();
	const mainTheme = useSelector(selectMainTheme);
	const [loading, setLoading] = useState(false);
	const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
	const [alert, setAlert] = useState(null);
	const [chosenResyncOption, setChosenResyncOption] = useState(null);
	const [chosenResyncDestination, setChosenResyncDestination] = useState(null);
	const [currentResyncTypes, setCurrentResyncTypes] = useState(defaultResyncTypes);
	const [currentResyncDestinations, setCurrentResyncDestinations] = useState(defaultResyncDestinations);
	const [snackbarMessage, setSnackbarMessage] = useState();

	useEffect(() => {
		const newResyncTypes = {
			[resyncTypes.ORDERS]: 'Ordre',
			[resyncTypes.CUSTOMER_SUBSCRIPTIONS]: 'Medlemskaber'
		};
		if (discountCodes) {
			newResyncTypes[resyncTypes.DISCOUNT_CODE_TYPES] = 'Rabatkodetyper';
		}
		if (bokunIntegration) {
			newResyncTypes[resyncTypes.BOKUN] = 'Bókun';
		}
		if (process.env.NODE_ENV === 'development') {
			setCurrentResyncTypes(oldTypes => {
				const copyTypes = { ...oldTypes };
				// delete copyTypes.elasticSearch;
				if (movedProductGroupsToElasticSearch) {
					copyTypes[resyncTypes.PRODUCT_GROUPS] = 'Produktgrupper';
				}
				delete copyTypes[resyncTypes.ELASTIC_SEARCH];
				if (!changeOfCustomerSyncToSubscriptionCardSync) {
					delete copyTypes[resyncTypes.SUBSCRIPTION_TYPES];
				}
				return {
					...copyTypes,
					[resyncTypes.CUSTOMER_TICKETS]: 'Billetter',
					...newResyncTypes,
					[resyncTypes.LOCAL_ELASTIC_SEARCH]: 'Elastic Search Lokalt'
				};
			});
		} else {
			setCurrentResyncTypes(oldTypes => {
				const copyTypes = { ...oldTypes };
				// delete copyTypes.elasticSearch;
				if (movedProductGroupsToElasticSearch) {
					copyTypes[resyncTypes.PRODUCT_GROUPS] = 'Produktgrupper';
				}
				delete copyTypes[resyncTypes.ELASTIC_SEARCH];
				if (!changeOfCustomerSyncToSubscriptionCardSync) {
					delete copyTypes[resyncTypes.SUBSCRIPTION_TYPES];
				}
				return {
					...copyTypes,
					[resyncTypes.CUSTOMER_TICKETS]: 'Billetter',
					...newResyncTypes
				};
			});
		}
		setCurrentResyncDestinations(_currentResyncDestinations => {
			return {
				..._currentResyncDestinations,
				[resyncDestinations.ELASTIC_SEARCH]: 'Elastic search',
				[resyncDestinations.FIRESTORE]: 'Firestore'
			};
		});
	}, [
		bokunIntegration,
		changeOfCustomerSyncToSubscriptionCardSync,
		movedProductGroupsToElasticSearch,
		discountCodes
	]);

	useEffect(() => {
		resetFormData();
	}, []);

	const getResyncOptionLabel = resyncOption => currentResyncTypes[resyncOption];

	const getDestinationOptionLabel = destinationOption => currentResyncDestinations[destinationOption];

	const resetFormData = () => {
		setChosenResyncOption(null);
		setChosenResyncDestination(null);
		setAlert(null);
	};

	const handleOnCloseDialog = async () => {
		onClose();
		resetFormData();
	};

	const handleOnClickResyncButton = async () => {
		if (chosenResyncOption === resyncTypes.BOKUN) {
			await handleResync(true);
		} else if (chosenResyncOption === resyncTypes.LOCAL_ELASTIC_SEARCH) {
			await handleResync(true);
		} else if (chosenResyncDestination === resyncDestinations.FLEXPOS) {
			if (chosenResyncOption === resyncTypes.CUSTOMERS) {
				const flexposLinkWithCustomerSyncActiveExists = await getFlexposLinkWithSyncActiveExists(
					syncTypes.CUSTOMER_SYNC
				);
				if (flexposLinkWithCustomerSyncActiveExists) {
					setShowConfirmationDialog(true);
				} else {
					setAlert({
						severity: 'warning',
						message:
							'Kunder kan ikke resynkroniseres til FlexPOS, da der ikke er aktiveret kunde synkronisering på et FlexPOS link'
					});
				}
			} else if (chosenResyncOption === resyncTypes.PRODUCTS) {
				const flexposLinkWithProductSyncActiveExists = await getFlexposLinkWithSyncActiveExists(
					syncTypes.PRODUCT_SYNC
				);
				if (flexposLinkWithProductSyncActiveExists) {
					setShowConfirmationDialog(true);
				} else {
					setAlert({
						severity: 'warning',
						message:
							'Produkter kan ikke resynkroniseres til FlexPOS, da der ikke er aktiveret produkt synkronisering på et FlexPOS link'
					});
				}
			}
		} else {
			setShowConfirmationDialog(true);
		}
	};

	const handleResync = async success => {
		setShowConfirmationDialog(false);
		if (success) {
			setLoading(true);
			if (chosenResyncOption === resyncTypes.BOKUN) {
				await firebaseService.callFunctionByName('sysAdminMigrationRunJob', {
					job: 'resyncBokun'
				});
			} else if (chosenResyncOption === 'localElasticSearch') {
				await firebaseService.callFunctionByName('sysAdminMigrationRunJob', {
					job: 'resyncToElasticSearch'
				});
			} else if (chosenResyncOption === 'bigQuery') {
				await firebaseService.callFunctionByName('bigQueryResyncBigQuery', {});
			} else {
				await resyncItemsToSyncEngine(chosenResyncOption, chosenResyncDestination);
			}

			setLoading(false);
			setSnackbarMessage(`Resynkronisering af ${getResyncOptionLabel(chosenResyncOption).toLowerCase()} startet`);
		}

		setShowConfirmationDialog(false);
		onClose();
	};

	const getDestinationOptions = () => {
		let destinationOptions = [];
		if (chosenResyncOption) {
			switch (chosenResyncOption) {
				case resyncTypes.CUSTOMERS:
				case resyncTypes.PRODUCTS:
					destinationOptions = [resyncDestinations.FLEXPOS, resyncDestinations.ELASTIC_SEARCH];
					break;
				case resyncTypes.DISCOUNT_CODE_TYPES:
				case resyncTypes.ORDERS:
				case resyncTypes.CUSTOMER_SUBSCRIPTIONS:
				case resyncTypes.PRODUCT_GROUPS:
					destinationOptions = [resyncDestinations.ELASTIC_SEARCH];
					break;
				case resyncTypes.SUBSCRIPTION_TYPES:
					destinationOptions = [resyncDestinations.FLEXPOS];
					break;
				case resyncTypes.INVOICES:
					destinationOptions = [resyncDestinations.FIRESTORE];
					break;
				case resyncTypes.CUSTOMER_TICKETS:
					if (changeOfCustomerSyncToSubscriptionCardSync) {
						destinationOptions = [resyncDestinations.FLEXPOS, resyncDestinations.ELASTIC_SEARCH];
					} else {
						destinationOptions = [resyncDestinations.ELASTIC_SEARCH];
					}
					break;
				default:
					destinationOptions = [];
			}
		}
		return destinationOptions;
	};

	const getResyncButtonDisabled = () => {
		let resyncButtonDisabled = true;
		if (chosenResyncOption) {
			if (
				chosenResyncOption === resyncTypes.CUSTOMERS ||
				chosenResyncOption === resyncTypes.PRODUCTS ||
				chosenResyncOption === resyncTypes.CUSTOMER_TICKETS ||
				chosenResyncOption === resyncTypes.ORDERS ||
				chosenResyncOption === resyncTypes.INVOICES ||
				chosenResyncOption === resyncTypes.CUSTOMER_SUBSCRIPTIONS ||
				chosenResyncOption === resyncTypes.PRODUCT_GROUPS ||
				chosenResyncOption === resyncTypes.SUBSCRIPTION_TYPES ||
				chosenResyncOption === resyncTypes.DISCOUNT_CODE_TYPES
			) {
				if (chosenResyncDestination) {
					resyncButtonDisabled = false;
				}
			} else {
				resyncButtonDisabled = false;
			}
		}
		return resyncButtonDisabled;
	};

	return (
		<ThemeProvider theme={mainTheme}>
			<>
				<Dialog
					open={open}
					onClose={handleOnCloseDialog}
					aria-labelledby="form-dialog-title"
					classes={{
						paper: 'rounded-8 min-w-md'
					}}
				>
					<AppBar position="static">
						<Toolbar className="flex w-full">
							<Typography variant="subtitle1" color="inherit">
								Synkroniserings administration
							</Typography>
						</Toolbar>
					</AppBar>

					<DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
						<Autocomplete
							className="mt-8 mb-16 w-full"
							options={Object.keys(currentResyncTypes)}
							value={chosenResyncOption}
							onChange={(event, newValue) => {
								setChosenResyncOption(newValue);
								setChosenResyncDestination(null);
								setAlert(null);
							}}
							getOptionLabel={getResyncOptionLabel}
							renderInput={params => (
								<TextField {...params} label="Resynkroniser..." variant="outlined" />
							)}
							openOnFocus
							disabled={loading}
						/>
						{chosenResyncOption &&
							(chosenResyncOption === resyncTypes.CUSTOMERS ||
								chosenResyncOption === resyncTypes.PRODUCTS ||
								chosenResyncOption === resyncTypes.CUSTOMER_TICKETS ||
								chosenResyncOption === resyncTypes.ORDERS ||
								chosenResyncOption === resyncTypes.INVOICES ||
								chosenResyncOption === resyncTypes.CUSTOMER_SUBSCRIPTIONS ||
								chosenResyncOption === resyncTypes.PRODUCT_GROUPS ||
								chosenResyncOption === resyncTypes.SUBSCRIPTION_TYPES ||
								chosenResyncOption === resyncTypes.DISCOUNT_CODE_TYPES) && (
								<Autocomplete
									className="mt-8 mb-16 w-full"
									options={getDestinationOptions()}
									value={chosenResyncDestination}
									onChange={(event, newValue) => {
										setChosenResyncDestination(newValue);
									}}
									getOptionLabel={getDestinationOptionLabel}
									renderInput={params => (
										<TextField {...params} label="Vælg destination" variant="outlined" />
									)}
									openOnFocus
									disabled={loading}
								/>
							)}
						{alert && (
							<Alert severity={alert.severity} className="mb-10">
								{alert.message}
							</Alert>
						)}
						<ConfirmationDialog
							onClose={handleResync}
							keepMounted
							open={showConfirmationDialog}
							needToType={getResyncOptionLabel(chosenResyncOption)}
							content={`Er du sikker på, at du vil resynkronisere ${getResyncOptionLabel(
								chosenResyncOption
							)}?`}
							okButton="Ja"
							cancelButton="Nej"
							loading={loading}
							maxWidth="sm"
						/>
					</DialogContent>
					<DialogActions className="justify-end p-8">
						<div className="px-16">
							<ButtonGroup>
								<Button variant="outlined" onClick={handleOnCloseDialog}>
									Annuller
								</Button>
								<Button
									variant="contained"
									color="primary"
									onClick={handleOnClickResyncButton}
									disabled={getResyncButtonDisabled()}
								>
									Resynkroniser
								</Button>
							</ButtonGroup>
						</div>
					</DialogActions>
				</Dialog>
				{ReactDOM.createPortal(
					<Snackbar
						autoHideDuration={10000}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						open={!!snackbarMessage}
						onClose={() => setSnackbarMessage(null)}
						message={<span>{snackbarMessage}</span>}
					/>,
					document.body
				)}
			</>
		</ThemeProvider>
	);
}

export default SyncAdminModal;
