import {lazy} from 'react';
import authRoles from '../../../../auth/authRoles';

const Countries = lazy(() => import('./countries/Countries'))

const CountriesAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/apps/settings/countries',
			element: <Countries />
		}
	]
};
export default CountriesAppConfig;
