import authRoles from '../../../../auth/authRoles';
import {lazy} from "react";

const Faqs = lazy(() => import('./faqs/Faqs'))

const FaqsAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/apps/frontend-settings/faqs',
			element: <Faqs />
		}
	]
};

export default FaqsAppConfig;
