import React, { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const Invoice = lazy(() => import('./invoice/Invoice'));
const Invoices = lazy(() => import('./Invoices'));

const InvoiceAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.ameroAdmin,
	routes: [
		{
			path: '/invoice/:organisationUid',
			element: <Invoice />
		},
		{
			path: '/invoices',
			element: <Invoices />
		}
	]
};

export default InvoiceAppConfig;
