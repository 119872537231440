import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { countriesListener } from './shared/countrySlice';
import { userListener } from './shared/userSlice';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { languagesListener } from '@ameroservices-platform/attraction-backend/app/main/apps/cms/cmsSlice';

function StoreInit({ children }) {
	const { differentViewsOnElasticSearchTables } = useFlags();
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);

	useEffect(() => {
		if (user && user.role && user.role.length > 0) {
			const unsubCountries = dispatch(countriesListener());
			let unsubUser = null;
			if (differentViewsOnElasticSearchTables) {
				unsubUser = dispatch(userListener(user.uid));
			}
			return () => {
				if (unsubCountries && unsubCountries instanceof Function) {
					unsubCountries();
				}
				if (unsubUser && unsubUser instanceof Function) {
					unsubUser();
				}
			};
		}
		return () => {};
	}, [dispatch, user]);

	useEffect(() => {
		const unsubLanguagesListener = dispatch(languagesListener());
		return () => {
			unsubLanguagesListener();
		};
	}, []);

	return children;
}

export default StoreInit;
