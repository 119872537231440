import {lazy} from 'react';
import authRoles from '../../../auth/authRoles';

const FollowProduct = lazy(() => import('./followProduct/FollowProduct'))
const FollowProducts = lazy(() => import('./followProducts/FollowProducts'))

const FollowProductsAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/apps/follow-products/:followProductUid',
			element: <FollowProduct />
		},
		{
			path: '/apps/follow-products',
			element: <FollowProducts />
		}
	]
};

export default FollowProductsAppConfig;
