import {lazy} from "react";

const Dashboard = lazy(() => import('./DashboardApp'))


const DashboardAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/apps/dashboard',
			element: <Dashboard />
		}
	]
};

export default DashboardAppConfig;
