import { selectNavigation, removeNavigationItem } from './app/store/fuse/navigationSlice';

export function makeRandomPass(length) {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i += 1) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export function removeNavChildren(dispatch, getState, id) {
	const navigation = selectNavigation(getState());
	const item = findNode(id, navigation);
	if (item.children) {
		item.children.forEach((child) => {
			dispatch(removeNavigationItem(child.id));
		});
	}
}

export function removeNavChildrenNoState(dispatch, navigation, id) {
	const item = findNode(id, navigation);
	if (item.children) {
		item.children.forEach(child => {
			dispatch(removeNavigationItem(child.id));
		});
	}
}

export function findNode(id, list) {
	for (let i = 0; i < list.length; i += 1) {
		const listElement = list[i];
		if (listElement.id === id) {
			return listElement;
		}
		if (listElement.children) {
			const result = findNode(id, listElement.children);
			if (result) {
				return result;
			}
		}
	}
	return null;
}

// eslint-disable-next-line import/prefer-default-export
export function getParameterByName(name, url = window.location.href) {
	// eslint-disable-next-line no-useless-escape
	name = name.replace(/[\[\]]/g, '\\$&');
	const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
	const results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
export function debugListener(listener) {
	if (window.location.hostname === 'localhost' || window.ATTdebugListener) {
		console.groupCollapsed('%c Listener', 'color: orange;', listener);
		console.trace();
		console.groupEnd();
	}
}

export const getFieldNameAndLanguageFromMultiLanguageTextField = (textField, languages) => {
	let fieldName = '';
	let fieldLanguage = '';
	if (textField.includes('_') && textField.split('_').length === 2) {
		[fieldName, fieldLanguage] = textField.split('_');
		if (languages && Object.values(languages).find(language => language.isoCode === fieldLanguage)) {
			return { fieldName, fieldLanguage };
		}
	}
	return null;
};
