import {lazy} from 'react';
import authRoles from '../../../../auth/authRoles';

const DiscountCodeType = lazy(() => import('./discountCodeType/DiscountCodeType'))
const DiscountCodeTypes = lazy(() => import('./discountCodeTypes/DiscountCodeTypes'))

const DiscountCodeTypesAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/apps/settings/discount-code-types/:discountCodeTypeUid',
			element: <DiscountCodeType />
		},
		{
			path: '/apps/settings/discount-code-types',
			element: <DiscountCodeTypes />
		}
	]
};

export default DiscountCodeTypesAppConfig;
