import React, { lazy } from 'react';

const FinanceDepartments = lazy(() => import('./FinanceDepartments'));

const FinanceDepartmentsAppConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/apps/finance-departments',
			element: <FinanceDepartments />,
		},
	],
};

export default FinanceDepartmentsAppConfig;
