import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import firebaseService from '@ameroservices-platform/shared/services/firebase';

const userSlice = createSlice({
	name: 'shared/user',
	initialState: {
		tableViews: {},
	},
	reducers: {
		setTableViews(state, action) {
			state.tableViews = action.payload;
		},
	},
});

export const { setTableViews } = userSlice.actions;

export const userListener = (userUid) => async (dispatch) => {
	const db = firebaseService.getRootDB();
	return db
		.collection('users')
		.doc(userUid)
		.onSnapshot((doc) => {
			const user = { ...doc.data(), id: doc.id };

			if (user && user.tableViews) {
				dispatch(setTableViews(JSON.parse(user.tableViews)));
			}
		});
};

export const updateTableViews = (userUid, tableId, tableView) => async (dispatch, getState) => {
	const state = getState();
	const cloneTableViews = _.cloneDeep(state.shared.user.tableViews);
	cloneTableViews[tableId] = tableView;
	const db = firebaseService.getRootDB();
	await db
		.collection('users')
		.doc(userUid)
		.update({ tableViews: JSON.stringify(cloneTableViews) });
};

export default userSlice.reducer;

export const selectTableViewByTableId = (state, id) =>
	state.shared.user.tableViews && state.shared.user.tableViews[id] ? state.shared.user.tableViews[id] : null;
