import {lazy} from 'react';
import authRoles from '../../../../auth/authRoles';

const List = lazy(() => import('./list/List'))
const Lists = lazy(() => import('./lists/Lists'))

const ListsAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/apps/settings/lists/:listUid',
			element: <List />
		},
		{
			path: '/apps/settings/lists',
			element: <Lists />
		}
	]
};

export default ListsAppConfig;
