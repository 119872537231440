import React, {lazy} from 'react';
import authRoles from '../../../../auth/authRoles';

const HeaderContentMultiLanguageCmsFeatureFlag = lazy(() => import('./headerContent/HeaderContentMultiLanguageCmsFeatureFlag'))
const HeaderLanguageContent = lazy(() => import('./headerContent/HeaderLanguageContent'))
const HeaderContentOld = lazy(() => import('./headerContent/HeaderContentOld'))
const MobileNavigationMenuContentMultiLanguageCmsFeatureFlag = lazy(() => import('./mobileNavigationMenuContent/MobileNavigationMenuContentMultiLanguageCmsFeatureFlag'))
const MobileNavigationMenuContentOld = lazy(() => import('./mobileNavigationMenuContent/MobileNavigationMenuContentOld'))
const MobileNavigationMenuLanguageContent = lazy(() => import('./mobileNavigationMenuContent/MobileNavigationMenuLanguageContent'))
const CheckoutHeaderContentMultiLanguageCmsFeatureFlag = lazy(() =>
	import('./checkoutHeaderContent/CheckoutHeaderContentMultiLanguageCmsFeatureFlag'));
const CheckoutHeaderLanguageContent = lazy(() => import('./checkoutHeaderContent/CheckoutHeaderLanguageContent'));
const CheckoutHeaderContentOld = lazy(() => import('./checkoutHeaderContent/CheckoutHeaderContentOld'))
const Header = lazy(() => import('./header/Header'))

const HeaderAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/apps/frontend-settings/header/header/:layoutElementUid/content',
			element: <HeaderContentMultiLanguageCmsFeatureFlag />
		},
		{
			path: '/apps/frontend-settings/header/header/:layoutElementUid/:languageUid/content',
			element: <HeaderLanguageContent />
		},
		{
			path: '/apps/frontend-settings/header/header-content',
			element: <HeaderContentOld />
		},
		/* {
			path: '/apps/frontend-settings/header/:languageUid/header-content',
			component: React.lazy(() => import('./headerContent/HeaderLanguageContent'))
		}, */
		{
			path: '/apps/frontend-settings/header/mobile-navigation-menu/:layoutElementUid/content',
			element: <MobileNavigationMenuContentMultiLanguageCmsFeatureFlag />
		},
		{
			path: '/apps/frontend-settings/header/mobile-navigation-menu/:layoutElementUid/:languageUid/content',
			element: <MobileNavigationMenuLanguageContent />
		},
		{
			path: '/apps/frontend-settings/header/mobile-navigation-menu-content',
			element: <MobileNavigationMenuContentOld />
		},
		/* {
			path: '/apps/frontend-settings/header/:languageUid/mobile-navigation-menu-content',
			component: React.lazy(() => import('./mobileNavigationMenuContent/MobileNavigationMenuLanguageContent'))
		}, */
		{
			path: '/apps/frontend-settings/header/checkout-header/:layoutElementUid/content',
			element: <CheckoutHeaderContentMultiLanguageCmsFeatureFlag />
		},
		{
			path: '/apps/frontend-settings/header/checkout-header/:layoutElementUid/:languageUid/content',
			element: <CheckoutHeaderLanguageContent />
		},
		{
			path: '/apps/frontend-settings/header/checkout-header-content',
			element: <CheckoutHeaderContentOld />
		},
		/* {
			path: '/apps/frontend-settings/header/:languageUid/checkout-header-content',
			component: React.lazy(() => import('./checkoutHeaderContent/CheckoutHeaderLanguageContent'))
		}, */
		{
			path: '/apps/frontend-settings/header',
			element: <Header />
		}
	]
};

export default HeaderAppConfig;
