import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function RedirectLink({ to, target }) {
	const navigate = useNavigate();

	useEffect(() => {
		window.open(to, target || '_self');
		navigate(-1);
	}, [to, target, navigate]);

	return '';
}
