import {lazy} from 'react';
import { authRoles } from '../../../../auth';

const PageContentWrapper = lazy(() => import('./pageContent/PageContentWrapper'));
const PageLanguageContent = lazy(() => import('./pageContent/PageLanguageContent'));
const PagePage = lazy(() => import('./page/Page'));
const PagesPages = lazy(() => import('./pages/Pages'));

const PagesAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/apps/frontend-settings/pages/:pageUid/content',
			element: <PageContentWrapper />
		},
		{
			path: '/apps/frontend-settings/pages/:pageUid/:languageUid/content',
			element: <PageLanguageContent />
		},
		{
			path: '/apps/frontend-settings/pages/:pageUid',
			element: <PagePage />,
		},
		{
			path: '/apps/frontend-settings/pages',
			element: <PagesPages />,
		},
	],
};

export default PagesAppConfig;
