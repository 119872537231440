import FaqsAppConfig from './faqs/FaqsAppConfig';
import ThemeAppConfig from './theme/ThemeAppConfig';
import NavigationsAppConfig from './navigations/NavigationsAppConfig';
import FooterAppConfig from './footer/FooterAppConfig';
import PagesAppConfig from './pages/PagesAppConfig';
import HeaderAppConfig from './header/HeaderAppConfig';

const appsConfigs = [
	FaqsAppConfig,
	ThemeAppConfig,
	NavigationsAppConfig,
	FooterAppConfig,
	HeaderAppConfig,
	PagesAppConfig
];

export default appsConfigs;
