import React, { lazy } from 'react';

const Budgets = lazy(() => import('./Budgets'));

const BudgetsAppConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/apps/budgets',
			element: <Budgets />,
		},
	],
};

export default BudgetsAppConfig;
