import {lazy} from 'react';
import authRoles from '../../../auth/authRoles';

const RecurringScheduledJob = lazy(() => import('./recurringScheduledJob/RecurringScheduledJob'))
const RecurringScheduledJobs = lazy(() => import('./recurringScheduledJobs/RecurringScheduledJobs'))

const RecurringScheduledJobsConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/apps/recurring-scheduled-jobs/:recurringScheduledJobId',
			element: <RecurringScheduledJob/>
		},
		{
			path: '/apps/recurring-scheduled-jobs/',
			element: <RecurringScheduledJobs/>
		}
	]
};

export default RecurringScheduledJobsConfig;
