// Internet Explorer 11 requires polyfills and partially supported by this project.
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import './i18n';
import './styles/app-base.css';
import './styles/app-components.css';
import './styles/app-utilities.css';
import ReactDOM from 'react-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import './react-chartjs-2-defaults';

ReactDOM.render(<App />, document.getElementById('root'));

export const mixpanel = require('mixpanel-browser');

reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
mixpanel.init('29a06c0bf8313c067b78869c33dcfc41', { debug: true, track_pageview: true, persistence: 'localStorage' });
