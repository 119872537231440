import { lazy } from 'react';
import authRoles from '../../../../auth/authRoles';

const MailTemplatesMailTemplates = lazy(() => import('./mailTemplates/MailTemplates'));

const MailTemplatesAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.ameroAdmin,
	routes: [
		{
			path: '/apps/settings/mail',
			element: <MailTemplatesMailTemplates />,
		},
	],
};

export default MailTemplatesAppConfig;
