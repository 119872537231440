import React, { lazy } from 'react';

const Statistics = lazy(() => import('./Statistics'));
const Statistic = lazy(() => import('./Statistic'));

const StatisticsAppConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/apps/statistics',
			element: <Statistics />,
		},
		{
			path: '/apps/statistics/:id',
			element: <Statistic />,
		},
	],
};

export default StatisticsAppConfig;
