import { createSlice } from '@reduxjs/toolkit';
import firebaseService from '@ameroservices-platform/shared/services/firebase';

const pagesSlice = createSlice({
	name: 'pagesApp',
	initialState: {
		loading: false,
		fetchingData: true,
		pages: {},
		pageDrafts: {},
		checkoutHeaderUrls: [],
		pageContentDefaultTemplate: {},
		languages: {},
		draftLanguages: {}
	},
	reducers: {
		setLoading: {
			reducer: (state, action) => {
				state.loading = action.payload;
			}
		},
		setFetchingData: {
			reducer: (state, action) => {
				state.fetchingData = action.payload;
			}
		},
		setPages: {
			reducer: (state, action) => {
				state.pages = action.payload;
			}
		},
		setPageDrafts: {
			reducer: (state, action) => {
				state.pageDrafts = action.payload;
			}
		},
		setCheckoutHeaderUrls: {
			reducer: (state, action) => {
				state.checkoutHeaderUrls = action.payload;
			}
		},
		setPageContentDefaultTemplate: {
			reducer: (state, action) => {
				state.pageContentDefaultTemplate = action.payload;
			}
		},
		setLanguages: {
			reducer: (state, action) => {
				state.languages = action.payload;
			}
		},
		setDraftLanguages: {
			reducer: (state, action) => {
				state.draftLanguages = action.payload;
			}
		}
	}
});

export const {
	setLoading,
	setFetchingData,
	setPages,
	setPageDrafts,
	setCheckoutHeaderUrls,
	setPageContentDefaultTemplate,
	setLanguages,
	setDraftLanguages
} = pagesSlice.actions;

export const pagesListener = () => dispatch => {
	const db = firebaseService.getContentRootDB();
	dispatch(setLoading(true));
	return db
		.collection('pages')
		.where('deleted', '==', false)
		.onSnapshot(query => {
			const pages = {};
			query.forEach(doc => {
				const page = doc.data();
				if (page.content && typeof page.content === 'string') {
					page.content = JSON.parse(page.content);
				}
				pages[doc.id] = { ...page, id: doc.id };
			});
			dispatch(setPages(pages));
			dispatch(setLoading(false));
		});
};

export const pageDraftsListener = () => dispatch => {
	const db = firebaseService.getContentRootDB();
	dispatch(setLoading(true));
	return db
		.collection('pageDrafts')
		.where('deleted', '==', false)
		.onSnapshot(query => {
			const pageDrafts = {};
			query.forEach(doc => {
				const pageDraft = doc.data();
				if (pageDraft.content && typeof pageDraft.content === 'string') {
					pageDraft.content = JSON.parse(pageDraft.content);
				}
				pageDrafts[doc.id] = { ...pageDraft, id: doc.id };
			});
			dispatch(setPageDrafts(pageDrafts));
			dispatch(setLoading(false));
		});
};

export const createPage = (url, content) => async dispatch => {
	dispatch(setLoading(true));
	const resp = await firebaseService.callFunctionByName('contentPagesCreatePage', {
		pageUrl: url,
		content: typeof content !== 'string' ? JSON.stringify(content) : content
	});
	dispatch(setLoading(false));
	return resp.data.id;
};

export const deletePage = pageUid => async dispatch => {
	dispatch(setLoading(true));
	await firebaseService.callFunctionByName('contentPagesDeletePage', {
		pageUid
	});
	dispatch(setLoading(false));
};

export const updatePage = (pageUid, data) => async dispatch => {
	dispatch(setLoading(true));
	if (typeof data.content !== 'string') {
		data.content = JSON.stringify(data.content);
	}
	await firebaseService.callFunctionByName('contentPagesUpdatePage', {
		pageUid,
		data
	});
	dispatch(setLoading(false));
};

export const savePage = pageUid => async dispatch => {
	dispatch(setLoading(true));
	await firebaseService.callFunctionByName('contentPagesSavePage', {
		pageUid
	});
	dispatch(setLoading(false));
};

export const savePageLanguage = (pageUid, languageUid) => async dispatch => {
	dispatch(setLoading(true));
	await firebaseService.callFunctionByName('contentPagesLanguagesSavePageLanguage', {
		pageUid,
		languageUid
	});
	dispatch(setLoading(false));
};

export const updatePageDraft = async (uid, data) => {
	const db = firebaseService.getContentRootDB();
	if (typeof data.content !== 'string') {
		data.content = JSON.stringify(data.content);
	}
	await db.collection('pageDrafts').doc(uid).update(data);
};

export const updatePageDraftLanguage = async (pageDraftUid, languageUid, data) => {
	const db = firebaseService.getContentRootDB();
	if (typeof data.content !== 'string') {
		data.content = JSON.stringify(data.content);
	}
	const pageDraftLanguageRef = db.collection('pageDrafts').doc(pageDraftUid).collection('languages').doc(languageUid);
	/* const pageDraftLanguageDoc = await db
		.collection('pageDrafts')
		.doc(pageDraftUid)
		.collection('languages')
		.doc(languageUid)
		.get(); */
	// if (pageDraftLanguageDoc) {
	await pageDraftLanguageRef.update(data);
	// }
};

export const checkoutHeaderUrlsListener = () => dispatch => {
	const db = firebaseService.getContentRootDB();
	dispatch(setLoading(true));
	return db.onSnapshot(q => {
		const data = q.data();
		if (data && data.checkoutHeaderUrls) {
			dispatch(setCheckoutHeaderUrls(data.checkoutHeaderUrls));
		}
		dispatch(setLoading(false));
	});
};

export const createCheckoutHeaderUrls = checkoutHeaderUrls => async dispatch => {
	dispatch(setLoading(true));
	const resp = await firebaseService.callFunctionByName('contentCheckoutHeaderUrlsCreateCheckoutHeaderUrls', {
		checkoutHeaderUrls
	});
	dispatch(setLoading(false));
	return resp;
};

export const addCheckoutHeaderUrl = checkoutHeaderUrl => async dispatch => {
	dispatch(setLoading(true));
	await firebaseService.callFunctionByName('contentCheckoutHeaderUrlsAddCheckoutHeaderUrl', {
		checkoutHeaderUrl
	});
	dispatch(setLoading(false));
};

export const removeCheckoutHeaderUrl = checkoutHeaderUrl => async dispatch => {
	dispatch(setLoading(true));
	await firebaseService.callFunctionByName('contentCheckoutHeaderUrlsRemoveCheckoutHeaderUrl', {
		checkoutHeaderUrl
	});
	dispatch(setLoading(false));
};

export const pageContentDefaultTemplateListener = () => dispatch => {
	const db = firebaseService.getContentRootDB();
	dispatch(setLoading(true));
	return db.onSnapshot(query => {
		const content = query.data();
		if (content && content.pageContentDefaultTemplate) {
			if (content.pageContentDefaultTemplate && typeof content.pageContentDefaultTemplate === 'string') {
				content.pageContentDefaultTemplate = JSON.parse(content.pageContentDefaultTemplate);
			}
			dispatch(setPageContentDefaultTemplate(content.pageContentDefaultTemplate));
		}
		dispatch(setLoading(false));
	});
};

export const createPageContentDefaultTemplate = data => async dispatch => {
	dispatch(setLoading(true));
	if (typeof data.pageContentDefaultTemplate !== 'string') {
		data.pageContentDefaultTemplate = JSON.stringify(data.pageContentDefaultTemplate);
	}
	const resp = await firebaseService.callFunctionByName(
		'contentPageContentDefaultTemplateCreatePageContentDefaultTemplate',
		{
			data
		}
	);
	dispatch(setLoading(false));
	return resp;
};

export const updatePageContentDefaultTemplate = data => async dispatch => {
	dispatch(setLoading(true));
	if (typeof data.pageContentDefaultTemplate !== 'string') {
		data.pageContentDefaultTemplate = JSON.stringify(data.pageContentDefaultTemplate);
	}
	await firebaseService.callFunctionByName('contentPageContentDefaultTemplateUpdatePageContentDefaultTemplate', {
		data
	});
	dispatch(setLoading(false));
};

export const createPageVersion = pageUid => async dispatch => {
	dispatch(setLoading(true));
	await firebaseService.callFunctionByName('contentPagesCreatePageVersion', {
		pageUid
	});
	dispatch(setLoading(false));
};

export const addLanguage = (pageUid, data) => async dispatch => {
	dispatch(setLoading(true));
	await firebaseService.callFunctionByName('contentPagesLanguagesAddLanguage', {
		pageUid,
		data
	});
	dispatch(setLoading(false));
};

export const removeLanguage = (pageUid, languageUid) => async dispatch => {
	dispatch(setLoading(true));
	await firebaseService.callFunctionByName('contentPagesLanguagesRemoveLanguage', {
		pageUid,
		languageUid
	});
	dispatch(setLoading(false));
};

export const pageLanguagesListener = pageUid => dispatch => {
	const db = firebaseService.getContentRootDB();
	dispatch(setLoading(true));
	return db
		.collection('pages')
		.doc(pageUid)
		.collection('languages')
		.where('deleted', '==', false)
		.onSnapshot(query => {
			const languages = {};
			query.forEach(doc => {
				const language = doc.data();
				languages[doc.id] = { ...language, id: doc.id };
			});
			dispatch(setLanguages(languages));
			dispatch(setLoading(false));
		});
};

export const pageDraftLanguagesListener = pageUid => dispatch => {
	const db = firebaseService.getContentRootDB();
	dispatch(setLoading(true));
	return db
		.collection('pageDrafts')
		.doc(pageUid)
		.collection('languages')
		.where('deleted', '==', false)
		.onSnapshot(query => {
			const draftLanguages = {};
			query.forEach(doc => {
				const draftLanguage = doc.data();
				draftLanguages[doc.id] = { ...draftLanguage, id: doc.id };
			});
			dispatch(setDraftLanguages(draftLanguages));
			dispatch(setLoading(false));
		});
};

export default pagesSlice.reducer;

export const selectPages = state => state.pagesApp.pages;
export const selectLoading = state => state.pagesApp.loading;
export const selectPageId = (state, id) => state.pagesApp.pages[id];
export const selectPageDraftId = (state, id) => state.pagesApp.pageDrafts[id];
export const selectCheckoutHeaderUrls = state => state.pagesApp.checkoutHeaderUrls;
export const selectPageContentDefaultTemplate = state => state.pagesApp.pageContentDefaultTemplate;
export const selectPageLanguages = state => state.pagesApp.languages;
export const selectLanguage = (state, id) => state.pagesApp.languages[id];
export const selectDraftLanguages = state => state.pagesApp.draftLanguages;
export const selectDraftLanguage = (state, id) => state.pagesApp.draftLanguages[id];
