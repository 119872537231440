import Card from '@mui/material/Card';
import { styled, darken } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import { useLocation } from 'react-router-dom';
import { getParameterByName } from '../../../AttUtils';

const Root = styled('div')(({ theme }) => ({
	background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
		theme.palette.primary.dark,
		0.5
	)} 100%)`,
	color: theme.palette.primary.contrastText,

	'& .logo-icon': {
		height: '3rem',
	},
}));
function AuthRedirect() {
	const user = useSelector(({ auth }) => auth.user);
	const { search } = useLocation();

	useEffect(() => {
		if (user && user.role && user.role.length <= 0) {
			// login
			const token = getParameterByName('token', search);
			firebaseService.auth.signInWithCustomToken(token);
		} else if (user && user.role && user.role.length > 0) {
			// redirect
			const redirect = getParameterByName('redirect', search);
			window.location.href = 'https://attraction.console.ameroservices.dk/' + decodeURIComponent(redirect);
		}
	}, [user, search]);

	return (
		<Root className="flex flex-col flex-auto items-center justify-center shrink-0 p-16 md:p-24">
			<motion.div
				initial={{ opacity: 0, scale: 0.6 }}
				animate={{ opacity: 1, scale: 1 }}
				className="min-w-400 rounded-20 shadow-2xl overflow-hidden"
			>
				<Card
					className="Login-leftSection flex flex-col w-full max-w-sm items-center justify-center shadow-0"
					square
				>
					<CardContent className="flex flex-col items-center justify-center w-full py-96 max-w-320">
						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
							<div className="flex items-center mb-48">
								<img className="logo-icon" src="assets/images/logos/amero.png" alt="logo" />
							</div>
						</motion.div>
						<div className="max-w-320">
							<div className={'flex justify-center w-full mb-32'}>
								<CircularProgress size={60} />
							</div>
							<motion.div
								initial={{ opacity: 0, y: 40 }}
								animate={{
									opacity: 1,
									y: 0,
									transition: { delay: 0.2 },
								}}
							>
								<Typography
									variant="h5"
									color="inherit"
									className="font-semibold leading-tight text-center"
								>
									Logger ind
								</Typography>
							</motion.div>

							<motion.div
								initial={{ opacity: 0 }}
								animate={{
									opacity: 1,
									transition: { delay: 0.3 },
								}}
							>
								<Typography variant="subtitle1" color="inherit" className="mt-10 text-center">
									Vent venligst på, at du bliver logget ind
								</Typography>
							</motion.div>
						</div>
					</CardContent>
				</Card>
			</motion.div>
		</Root>
	);
}

export default AuthRedirect;
