import { createSlice } from '@reduxjs/toolkit';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import countries from '../../countries';

const countrySlice = createSlice({
	name: 'shared/country',
	initialState: {
		countries: {},
	},
	reducers: {
		setCountries(state, action) {
			state.countries = action.payload;
		},
	},
});

export const { setCountries } = countrySlice.actions;

export const countriesListener =
	(language = 'DA_dk') =>
	async (dispatch) => {
		if (firebaseService.getOrganisationId()) {
			const db = firebaseService.getContentRootDB();
			return db.onSnapshot((doc) => {
				const content = doc.data();
				const c =
					content && content.countries
						? content.countries
							.filter((code) => countries[code])
							.reduce((accCountries, curCountry) => {
								accCountries[curCountry] = countries[curCountry][language];
								return accCountries;
							}, {})
					: {
							DK: countries.DK[language],
							FO: countries.FO[language],
							GL: countries.GL[language],
					  };
				const ordered = {};
				Object.keys(c)
					.sort()
					.forEach((key) => {
						ordered[key] = c[key];
					});
				dispatch(setCountries(ordered));
			});
		}
	};

export default countrySlice.reducer;

export const selectCountries = (state) => state.shared.country.countries;
