import React, { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const Redirect = lazy(() => import('./Redirect'));

const RedirectAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/redirect',
			element: <Redirect />,
		},
	],
};

export default RedirectAppConfig;
